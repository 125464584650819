<template>
  <div>
    <CProgress class="mb-3">
      <CSpinner v-if="loading" variant="grow" size="lg"/>
    </CProgress>
    <CRow>
      <CCol sm="6" md="4" v-for="product in products" :key="product.ItemCode">
        <CCard>
          <img class="imageProduct" :src="product.UserText" alt="" width="100%">
          <CCardBody>
            <CCardTitle><center><strong>{{ product.ItemName }}</strong></center></CCardTitle>
            <CCardText>
              <span>
                Compra minima: {{ configs.filter(item => item.ItemCode === product.ItemCode).length > 0 ? 
                  configs.filter(item => item.ItemCode === product.ItemCode)[0].min
                  : 1 }}
              </span>
            </CCardText>
            <CCardTitle><center> ${{ formatPrice(product.Price) }} </center></CCardTitle>
            <CCardText><span v-html="product.description"></span></CCardText>
          </CCardBody>
          <CCardFooter>
            <center>
              <div class="qtyContainer">
                <CButton class="lessButton" color="info" @click="changeProductQuantity(product, 'minus')">-</CButton>
                <CInput
                  class="qtyInput"
                  v-model="product.quantity"
                  required
                  @keypress="isNumber($event)"
                  @change="changeProductQuantity(product, 'change')"
                />
                  <CButton class="addButton" color="info" @click="changeProductQuantity(product, 'plus')">+</CButton>
                </div>
              <CButton class="cartAdd" color="info" @click="(event) => $store.commit('add', product)"><font-awesome-icon icon="cart-plus" /></CButton>
            </center>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Resource from '@/api/resource'
const resourceItems = new Resource("items");
const resourceConfig = new Resource("config/products");

export default {
  name: 'Product',
  data: function () {
    return {
      loading: false,
      form: {},
      products: [],
      configs: [],
      lessons: [],
      show: true,
      isCollapsed: true,
      loremIpsum: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.'
    }
  },
  async mounted() {
    await this.loadConfig();
    await this.loadItems();
  },
  methods: {
    async loadItems(){
      this.loading = true;
      await resourceItems.list().then(response => {
        var clean = response.data.filter(
          (arr, index, self) =>
            index === self.findIndex((t) => (t.ItemCode === arr.ItemCode))
          ).filter(function(item){
            return !item.ItemCode.includes('DST');
          });
        for (let i = 0; i < clean.length; i++) {
          clean[i].quantity=0;
        }
        this.products = clean;
        this.loading = false;
      }).catch(error => {
        //console.log(error);
        this.loading = false;
      });
    },
    formatPrice(value) {
      let val = (value/1).toFixed(2).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    toKebabCase (str) {
      return str.replace(/([a-z])([A-Z0-9])/g, '$1-$2').toLowerCase()
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
    add(product){
      product.quantity++
    },
    less(product){
      product.quantity--
    },
    async loadConfig() {
      this.loading = true;
      try {
        const response = await resourceConfig.list();
        if (response.status == 200) {
          const { data } = response.data;
          this.configs = data;
        } else {
          console.log(response);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    changeProductQuantity(product, type){
      const min = this.configs.filter(item => item.ItemCode === product.ItemCode).length > 0 ? 
        this.configs.filter(item => item.ItemCode === product.ItemCode)[0].min
        : 1;
      if(type === 'minus'){
        if(product.quantity > 0){
          const temp = product.quantity -1;
          if(temp > 0){
            if(temp >= min){
              product.quantity = temp;
            } else {
              product.quantity = 0;
            }
          } else {
            product.quantity = 0;
          }
        }
      } 
      if(type === 'plus'){
        const temp = product.quantity +1;
        if(temp > 0){
          if(temp < min){
            product.quantity = min;
          } else {
            product.quantity = temp;
          }
        } else {
          product.quantity = temp;
        }
      }
      if(type === 'change'){
        const temp = product.quantity;
        if(temp < min && temp !== 0){
          product.quantity = 0;
        }
      }
    },
  }
}
</script>

<style scoped>
.cartAdd{
  font-size: large;
  border-radius: 50%;
  background: #00454e;
  border-color: #3c4b64;
}
.qtyInput{
  display: inline-flex;
  height: 35px!important;
  width: 47px;
}
.lessButton{
  border-radius: 50% 0px 0px 50%;
  height: 35px!important;
  background: #00454e;
  border-color: #00454e;
}
.addButton{
  border-radius: 0px 50% 50% 0px;
  height: 35px!important;
  background: #3c4b64;
  border-color: #3c4b64;
}

.form-control {
  -moz-appearance: textfield;
  -webkit-appearance: none;
  margin: 0;
}

.qtyContainer{
  display: inline-flex;
  margin-right: 50px;
}

</style>